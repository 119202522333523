import React, { Suspense, lazy } from "react";
import styled from "styled-components";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import SEOHelmet from "./components/SEOHelmet";
import LoadingSpinner from "./components/LoadingSpinner";
import RedirectHandler from "./components/RedirectHandler";
import ErrorBoundary from "./components/ErrorBoundary";
import { HelmetProvider } from "react-helmet-async";
import { AppProvider } from "./context/AppContext";

// Lazy load components
const AboutMe = lazy(() => import("./pages/AboutMe"));
const Bits = lazy(() => import("./pages/Bits"));
const Bites = lazy(() => import("./pages/Bites"));
const BitesPicks = lazy(() => import("./pages/BitesPicks"));
const Blog = lazy(() => import("./pages/Blog"));
const Impressum = lazy(() => import("./pages/Impressum"));

// Route configuration for cleaner code
const routes = [
  {
    path: "/",
    element: <Navigate to="/about" replace />,
    seo: {
      title: "About",
      description: "Hey I am Adrian Henkel, a Data Scientist with a background in Bioinformatics."
    }
  },
  {
    path: "/about",
    element: <AboutMe />,
    seo: {
      title: "About",
      description: "Hey I am Adrian Henkel, a Data Scientist with a background in Bioinformatics."
    }
  },
  {
    path: "/bits",
    element: <Bits />,
    seo: {
      title: "Bits",
      description: "Coding Tips and Tricks."
    }
  },
  {
    path: "/bites-recipes",
    element: <Bites />,
    seo: {
      title: "Bites Recipes",
      description: "Simple and good recipes."
    }
  },
  {
    path: "/bites-picks",
    element: <BitesPicks />,
    seo: {
      title: "Bites Picks",
      description: "Places I can recommend."
    }
  },
  {
    path: "/blog",
    element: <Blog />,
    seo: {
      title: "Blog",
      description: "Thoughts on data science, bioinformatics, and more."
    }
  },
  {
    path: "/impressum",
    element: <Impressum />,
    seo: {
      title: "Impressum",
      description: "Legal information about Adrian Henkel's website."
    }
  }
];

const AppContainer = styled.div`
  padding-top: 60px; // Add space for the fixed navbar
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  flex: 1;
`;

function App() {
  return (
    <HelmetProvider>
      <AppProvider>
        <Router>
          <AppContainer>
            <Navbar />
            <ContentContainer>
              <ErrorBoundary>
                <Suspense fallback={<LoadingSpinner />}>
                  <Routes>
                    {routes.map((route) => (
                      <Route
                        key={route.path}
                        path={route.path}
                        element={
                          <>
                            <SEOHelmet
                              title={route.seo.title}
                              description={route.seo.description}
                            />
                            {route.element}
                          </>
                        }
                      />
                    ))}
                    <Route path="*" element={<RedirectHandler />} />
                  </Routes>
                </Suspense>
              </ErrorBoundary>
            </ContentContainer>
            <Footer />
          </AppContainer>
        </Router>
      </AppProvider>
    </HelmetProvider>
  );
}

export default App;
