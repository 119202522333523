import React from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

/**
 * SEOHelmet - A component for managing SEO metadata
 * 
 * @param {string} title - The page title
 * @param {string} description - The page description
 * @param {string} canonical - Optional canonical URL
 * @param {object} openGraph - Optional Open Graph metadata
 * @param {object} structuredData - Optional JSON-LD structured data
 */
const SEOHelmet = ({ 
  title, 
  description, 
  canonical,
  openGraph,
  structuredData
}) => (
  <Helmet>
    {/* Basic metadata */}
    <title>{title} | Adrian Henkel</title>
    <meta name="description" content={description} />
    
    {/* Canonical URL */}
    {canonical && <link rel="canonical" href={canonical} />}
    
    {/* Open Graph / Social Media */}
    {openGraph && (
      <>
        <meta property="og:title" content={openGraph.title || title} />
        <meta property="og:description" content={openGraph.description || description} />
        <meta property="og:type" content={openGraph.type || "website"} />
        {openGraph.url && <meta property="og:url" content={openGraph.url} />}
        {openGraph.image && <meta property="og:image" content={openGraph.image} />}
        {openGraph.siteName && <meta property="og:site_name" content={openGraph.siteName} />}
      </>
    )}
    
    {/* Structured Data / JSON-LD */}
    {structuredData && (
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    )}
  </Helmet>
);

SEOHelmet.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  canonical: PropTypes.string,
  openGraph: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    url: PropTypes.string,
    image: PropTypes.string,
    siteName: PropTypes.string
  }),
  structuredData: PropTypes.object
};

export default SEOHelmet;