import React, { createContext, useState, useContext, useEffect } from 'react';

// Create app context for shared state
const AppContext = createContext();

// Custom hook for using app context
export const useAppContext = () => useContext(AppContext);

// Context provider component
export const AppProvider = ({ children }) => {
  // Mobile detection state
  const [isMobile, setIsMobile] = useState(false);
  
  // Filter states for BitesPicks
  const [locationFilter, setLocationFilter] = useState("");
  const [priceFilter, setPriceFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("");
  
  // Check for mobile device on mount
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile("ontouchstart" in window || navigator.maxTouchPoints > 0);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  // Handle cursor effect for desktop
  useEffect(() => {
    if (!isMobile) {
      const cursor = document.getElementById("cursor");
      if (!cursor) return;
      
      const onMouseMove = (e) => {
        cursor.style.left = `${e.clientX}px`;
        cursor.style.top = `${e.clientY}px`;
      };
      
      document.addEventListener("mousemove", onMouseMove);
      return () => {
        document.removeEventListener("mousemove", onMouseMove);
      };
    }
  }, [isMobile]);

  return (
    <AppContext.Provider
      value={{
        // Mobile detection
        isMobile,
        
        // BitesPicks filters
        locationFilter,
        setLocationFilter,
        priceFilter,
        setPriceFilter,
        typeFilter,
        setTypeFilter,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;